.mapboxgl-ctrl-group {
  display: none;
}

.mapboxgl-popup-content {
  padding: 0;
  background: rgba(0, 0, 0, 0) !important;
  box-shadow: none !important;
}

.mapboxgl-popup-anchor-bottom .mapboxgl-popup-tip {
  border-top-color: #fff;
}

.mapboxgl-popup-anchor-top .mapboxgl-popup-tip {
  border-bottom-color: #fff;
}

.mapboxgl-popup-anchor-left .mapboxgl-popup-tip {
  border-right-color: #fff;
}

.mapboxgl-popup-anchor-right .mapboxgl-popup-tip {
  border-left-color: #fff;
}

.mapboxgl-popup-close-button {
  right: 5px;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  background-color: transparent !important;
  box-shadow: 0 0 0 50px white inset;
  -webkit-box-shadow: 0 0 0 50px white inset;
}

canvas:focus {
  outline-color: #1b2a33;
}

label[itemid='toggle-model'] > div {
  box-shadow: none !important;
}

.instructionList > li::marker{
  content: "\2022    ";
  font-size: large;
  color: #575759;
}

.hideScrollbar {
  scrollbar-width: none;
  -ms-overflow-style: none;
}
.hideScrollbar::-webkit-scrollbar {
  width: 0;
  height: 0;
}
